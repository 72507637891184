(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

/* ========================================================================
* Glucos JavaScript main engine room
* ====================================================================== */
(function ($, window, document, undefined) {
  'use strict';

  var DOM = {
    $window: $(window),
    $body: $('body'),
    $mainMenu: $('#main-menu'),
    mocm: null
  };
  var A = [{
    from: '(.*)',
    to: '(.*)',
    out: function out(next) {
      if ($('body').hasClass('is-overlay-active')) {
        $('#menuButton').removeClass('is-active');
        DOM.mocm.reverse();
        $('body').removeClass('is-overlay-active');
      }

      gsap.set('#main', {
        autoAlpha: 1
      });
      DOM.$mainMenu.find('a.menu-item.current').removeClass('current');
      gsap.to('#main', {
        autoAlpha: 0,
        duration: 0.7,
        ease: 'power3',
        onComplete: next
      });
    },
    "in": function _in(next) {
      gsap.set(DOM.$window, {
        duration: 0,
        scrollTo: 0
      });
      gsap.set('#main', {
        autoAlpha: 0
      });
      gsap.to('#main', {
        autoAlpha: 1,
        duration: 1,
        ease: 'power3',
        onComplete: next
      });
    }
  }];
  var R = new Swup({
    containers: ['#main'],
    cache: true,
    plugins: [new SwupJsPlugin(A), new SwupBodyClassPlugin()]
  });
  var U = {
    loadCSS: function loadCSS(src) {
      var ref = window.document.getElementsByTagName("link")[0];
      var style = window.document.createElement("link");
      style.rel = 'stylesheet';
      style.href = src;
      style.type = 'text/css';
      ref.parentNode.insertBefore(style, ref);
      return style;
    },
    loadJS: function loadJS(src, cb, ordered) {
      var tmp;
      var ref = window.document.getElementsByTagName("script")[0];
      var script = window.document.createElement("script");

      if (typeof cb === 'boolean') {
        tmp = ordered;
        ordered = cb;
        cb = tmp;
      }

      script.src = src;
      script.async = !ordered;
      ref.parentNode.insertBefore(script, ref);

      if (cb && typeof cb === "function") {
        script.onload = cb;
      }

      return script;
    },
    getUrlParameter: function getUrlParameter(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
  };
  var X = {
    'common': function common(args) {
      DOM.$mainMenu.find('a.menu-item[href="' + window.location.href + '"]').addClass('current');
      $('body').on('click', '.scrolldown > button', function (e) {
        gsap.to(window, {
          duration: 1,
          scrollTo: '.main',
          ease: 'power3.inOut'
        });
      });

      if (device.mobile()) {
        DOM.mocm = gsap.timeline({
          paused: true
        });
        DOM.mocm.fromTo('#mocm', {
          autoAlpha: 0,
          x: 200
        }, {
          autoAlpha: 1,
          x: 0
        }); // Mobile Menu Burger

        $(document).off().on('click', '#menuButton', function (e) {
          var $that = $(this);

          if ($that.hasClass('is-active')) {
            DOM.mocm.reverse();
            $that.removeClass('is-active');
            $('body').removeClass('is-overlay-active');
          } else {
            $('body').addClass('is-overlay-active');
            $that.addClass('is-active');
            DOM.mocm.play();
          }
        });
      }
    },
    'home': function home(args) {
      // Intro Animations
      gsap.to('.bg-cover.invisible', 1, {
        autoAlpha: 1,
        stagger: 0.2,
        ease: 'power3.inOut'
      });
      gsap.fromTo(['.title.invisible', '.calltoaction.invisible'], 0.6, {
        y: 40
      }, {
        autoAlpha: 1,
        y: 0,
        stagger: 0.1,
        delay: 0.8,
        ease: 'power3.inOut'
      });
    },
    'about': function about(args) {
      // Intro Animations
      gsap.fromTo(['.header .cover', '.header .title'], 0.8, {
        x: 80
      }, {
        autoAlpha: 1,
        x: 0,
        stagger: 0.2,
        ease: 'power3.inOut'
      });
      gsap.set("#whoweare p", {
        autoAlpha: 0,
        y: 80
      });
      gsap.to("#whoweare p", 0.8, {
        scrollTrigger: {
          trigger: "#whoweare",
          start: 'top center'
        },
        autoAlpha: 1,
        y: 0,
        ease: 'power3'
      });
      gsap.set("#philosophy .media", {
        y: -40
      });
      gsap.set("#philosophy .content .invisible", {
        x: 40
      });
      gsap.to("#philosophy .media", 0.8, {
        scrollTrigger: {
          trigger: "#philosophy",
          start: 'top center'
        },
        autoAlpha: 1,
        y: 0,
        ease: 'power3.inOut'
      });
      gsap.to("#philosophy .content .invisible", 0.6, {
        scrollTrigger: {
          trigger: "#philosophy",
          start: 'top center'
        },
        autoAlpha: 1,
        x: 0,
        stagger: 0.1,
        delay: 0.8,
        ease: 'power3.inOut'
      });
      gsap.set("#structure .content", {
        x: 40
      });
      gsap.set(["#structure .media", "#structure .label"], {
        y: 40
      });
      gsap.to("#structure .content", 0.6, {
        scrollTrigger: {
          trigger: "#structure .trigger",
          start: 'top 40%'
        },
        autoAlpha: 1,
        x: 0,
        stagger: 0.1,
        ease: 'power3.inOut'
      });
      gsap.to(["#structure .media, #structure .label"], 0.6, {
        scrollTrigger: {
          trigger: "#structure .trigger",
          start: 'top 30%'
        },
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        delay: 0.8,
        ease: 'power3.inOut'
      });
      gsap.set("#values .item", {
        x: 60
      });
      gsap.to("#values .item", 0.8, {
        scrollTrigger: {
          trigger: "#values",
          start: '30% 40%',
          end: '30% 40%'
        },
        autoAlpha: 1,
        x: 0,
        stagger: 0.1,
        ease: 'power3'
      });
    },
    'business': function business(args) {
      // Intro Animations
      gsap.fromTo(['.header .page-title', '.header .page-subtitle'], 0.8, {
        y: 40
      }, {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        ease: 'power3.inOut'
      }); // Piechart

      gsap.fromTo("#piechart .chart", 0.8, {
        rotate: -135
      }, {
        scrollTrigger: {
          trigger: "#piechart",
          start: '30% 40%',
          end: '30% 40%'
        },
        autoAlpha: 1,
        rotate: 0,
        ease: 'power3'
      });
      gsap.fromTo("#piechart .icon", 0.8, {
        autoAlpha: 0,
        y: 15
      }, {
        scrollTrigger: {
          trigger: "#piechart",
          start: '30% 40%',
          end: '30% 40%'
        },
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        delay: 0.4,
        ease: 'power3.inOut'
      });
      gsap.fromTo("#piechart .label", 0.4, {
        y: -20
      }, {
        scrollTrigger: {
          trigger: "#piechart",
          start: '30% 40%',
          end: '30% 40%'
        },
        autoAlpha: 1,
        y: 0,
        stagger: 0.1,
        delay: 0.6,
        ease: 'power3.inOut'
      }); // Businnesses

      $('.investment').each(function (i, el) {
        var even = $(el).hasClass('even');
        gsap.fromTo($(el).find(".meta .invisible"), 0.8, {
          y: 35
        }, {
          scrollTrigger: {
            trigger: $(el),
            start: '10% 40%',
            end: '10% 40%'
          },
          autoAlpha: 1,
          y: 0,
          stagger: 0.05,
          ease: 'power3'
        });
        gsap.fromTo($(el).find(".content .invisible"), 0.6, {
          y: 35
        }, {
          scrollTrigger: {
            trigger: $(el),
            start: '10% 40%',
            end: '10% 40%'
          },
          autoAlpha: 1,
          y: 0,
          delay: 0.6,
          stagger: 0.15,
          ease: 'power3.inOut'
        });
        gsap.fromTo($(el).find(".media"), 0.8, {
          x: even ? 40 : -40
        }, {
          scrollTrigger: {
            trigger: $(el),
            start: '10% 40%',
            end: '10% 40%'
          },
          autoAlpha: 1,
          x: 0,
          delay: 1.2,
          ease: 'power3'
        });
      });
    },
    'food': function food(args) {
      // Intro Animations
      gsap.fromTo('.header .page-title', 0.8, {
        y: 40
      }, {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        ease: 'power3.inOut'
      }); // Sticky + Sticky Navigation

      gsap.to(".side-nav-wrapper", {
        scrollTrigger: {
          trigger: ".side-nav",
          start: 'top 25%',
          pin: true,
          end: 'bottom 65%',
          pinSpacing: false
        }
      });
      $(document).on('click', 'button[data-target]', function () {
        var $that = $(this),
            target = $that.data('target');
        gsap.to(window, 0.8, {
          scrollTo: '#' + target,
          ease: 'power3.inOut'
        });
      });
    },
    'contacts': function contacts(args) {
      // Intro Animations
      gsap.fromTo(['.header .page-title', '.header .page-subtitle'], 0.8, {
        y: 40
      }, {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        ease: 'power3.inOut'
      });
      gsap.fromTo(".location", 0.8, {
        y: 35
      }, {
        scrollTrigger: {
          trigger: '#locations',
          start: '10% 40%',
          end: '10% 40%'
        },
        autoAlpha: 1,
        y: 0,
        stagger: 0.05,
        ease: 'power3.inOut'
      });
      gsap.fromTo("#contact .flex-row.invisible", 0.8, {
        y: 35
      }, {
        scrollTrigger: {
          trigger: '#contact',
          start: '10% 40%',
          end: '10% 40%'
        },
        autoAlpha: 1,
        y: 0,
        stagger: 0.15,
        ease: 'power3.inOut'
      });

      if (!device.mobile()) {
        U.loadCSS('https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css');
        U.loadJS('https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.js', function () {
          mapboxgl.accessToken = 'pk.eyJ1IjoiaG9ydGVsYW1hZ2VudGEiLCJhIjoiY2tjcGdoMnlqMHVwOTJ4bGZzcTFmYnhrciJ9.e8fu5Kp-ob-cHPktKbT0ug';
          fetch(window.location.href + '.json', {
            method: 'GET',
            headers: {
              'X-Requested-With': 'fetch'
            }
          }).then(function (response) {
            return response.json();
          }).then(function (locations) {
            var map = new mapboxgl.Map({
              container: 'map-inner',
              style: 'mapbox://styles/mapbox/light-v10',
              center: locations.locations.location_0.center,
              zoom: locations.mapbox.zoom
            }); // map.scrollZoom.disable();

            map.on("load", function () {
              map.loadImage(locations.marker, function (error, image) {
                if (error) throw error;
                map.addImage("ff-marker", image);
                map.addLayer({
                  id: "markers",
                  interactive: true,
                  type: "symbol",
                  source: {
                    type: "geojson",
                    data: {
                      type: 'FeatureCollection',
                      features: locations.geojson
                    }
                  },
                  layout: {
                    "icon-image": "ff-marker"
                  }
                });
              });
              gsap.to("#locations", {
                scrollTrigger: {
                  trigger: '#locations',
                  start: '10% 40%',
                  end: '10% 40%'
                },
                onComplete: function onComplete() {
                  map.flyTo({
                    curve: 2,
                    speed: 0.5,
                    center: locations.locations.location_0.center,
                    zoom: 12,
                    essential: true
                  });
                  $('.location button[data-target="' + locations.locations.location_0.id + '"]').addClass('active-location');
                }
              });

              if (!device.mobile()) {
                $('#locations').off().on('click', '.location-trigger', function () {
                  var $that = $(this),
                      $activeLocation = $('.active-location');

                  if (!$that.is($activeLocation)) {
                    var target = $that.data('target');
                    $activeLocation.removeClass('active-location');
                    $that.addClass('active-location');
                    map.flyTo({
                      curve: 2,
                      speed: 0.5,
                      center: locations.locations[target].center,
                      zoom: 12,
                      essential: true
                    });
                  }
                });
              }
            });
          });
        });
      }

      var $form = $(".contact-form"),
          fields = {};
      $form.find('[required]').each(function (i, field) {
        fields[field.name] = $(field);
      });
      $form.submit(function (e) {
        e.preventDefault();
        var $that = $(this),
            $formMiddle = $('.glucos-form').find('.form-middle'),
            $formFooter = $('.glucos-form').find('.form-footer'),
            url = $that.attr('action');
        $that.find('.error').removeClass('error');
        $that.addClass('disabled');
        $('body').addClass('working');
        fetch(url, {
          method: 'POST',
          body: $that.serialize()
        }).then(function (r) {
          return r.json().then(function (data) {
            return {
              status: r.status,
              body: data
            };
          });
        }).then(function (result) {
          if (400 === result.status) {
            $that.removeClass('disabled');
            var errors = [];

            for (var key in result.body) {
              if (!(key in fields)) continue;

              if (key in fields) {
                fields[key].closest('.field').addClass('error');
                fields[key].after('<span class="not-valid-tip">' + result.body[key] + '</div>');
              }
            }
          } else if (200 === result.status) {
            gsap.to($formMiddle, 0.6, {
              autoAlpha: 0,
              y: -15,
              delay: 0.2
            });
            gsap.fromTo($formFooter, 0.6, {
              autoAlpha: 0,
              y: 15,
              delay: 0.6
            }, {
              autoAlpha: 1,
              y: 0,
              onComplete: function onComplete() {
                $form.find('[required]').each(function (i, field) {
                  if ($(field).is(':checkbox')) {
                    $(field).prop("checked", false);
                  } else {
                    $(field).val("");
                  }
                });
                $that.removeClass('disabled');
                $('body').removeClass('working');
              }
            });
            gsap.to($formFooter, 0.6, {
              delay: 5,
              autoAlpha: 0,
              y: -15
            });
            gsap.fromTo($formMiddle, 0.6, {
              autoAlpha: 0,
              y: 15
            }, {
              autoAlpha: 1,
              y: 0,
              delay: 5.4
            });
          }
        });
      });
    },
    'default': function _default(args) {}
  };
  var _ = {
    // fire: function(func, funcname, args) {
    fire: function fire(func, args) {
      var fire; // funcname = (funcname === undefined) ? 'init' : funcname;

      fire = func !== ''; // fire = fire && X[func];
      // fire = fire && typeof X[func][funcname] === 'function';

      fire = fire && typeof X[func] === 'function';

      if (fire) {
        // X[func][funcname](args);
        X[func](args);
      }
    },
    load: function load() {
      // Fire common init JS
      _.fire('common'); // Fire page-specific init JS, and then finalize JS


      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        _.fire(classnm); // _.fire(classnm, 'finalize');

      }); // Fire common finalize JS
      // _.fire('common', 'finalize');
    }
  };

  _.load();

  R.on('contentReplaced', function () {
    _.load();
  });
})(jQuery, window, document);

},{}]},{},[1]);
